import React, { useState, useRef } from "react"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as api from "../components/context/api"
import { useAuth } from "../components/context/auth"
import { CubeTransparentIcon } from "@heroicons/react/outline"
import store from "store"
import _ from "lodash"
import { GetToken } from "../components/context/reactQuery"
import { useMutation } from "react-query"
import { SplitName } from "./register"
import uniqueSlug from "unique-slug"

const Login = () => {
  const { login, loginWithGoogle, currentUser } = useAuth()
  const emailRef = useRef()
  const passwordRef = useRef()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  if (currentUser?.email) {
    navigate("/app/home")
  }

  const mutation = useMutation(api.postStudent, {
    onSuccess: async result => {
      const user = _.pick(result.data, [
        "firstName",
        "lastName",
        "studentId",
        "email",
      ])
      store.set("user", user)
      navigate("/app/home", { replace: true })
      setLoading(false)
    },
    onError: () => {
      setError("Error, please try again")
      setLoading(false)
    },
  })

  const handleSubmit = async e => {
    e.preventDefault()
    const email = emailRef.current.value.trim()
    const password = passwordRef.current.value
    try {
      setError("")
      setLoading(true)
      await login(email, password)
      GetToken()
      navigate("/app/home", { replace: true })
    } catch {
      setError("Failed to log in")
    }

    setLoading(false)
  }

  const googleAuthHandler = async e => {
    try {
      const result = await loginWithGoogle()

      setLoading(true)
      let names = SplitName(result.user.displayName)
      let value = {
        ...names,
        mobileNo: result.user.phoneNumber,
        email: result.user.email,
        studentId: uniqueSlug(result.user.email),
      }
      mutation.mutate(value)
      // navigate("/app/home", { replace: true })
    } catch (err) {
      setError("Failed to create an account")
    }
  }

  return (
    <>
      {currentUser ? (
        <div className="flex items-center justify-center h-screen">
          <CubeTransparentIcon className="w-8 h-8 mr-2 animate-spin" />
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-screen mx-auto">
          <div className="w-full max-w-sm p-6 m-auto bg-white md:shadow rounded-xl dark:bg-gray-800">
            <h1 className="hidden text-3xl font-semibold text-center lg:block text-skin-inverted dark:text-white">
              <a href="https://acadmi.in">acadmi</a>
            </h1>
            <h1 className="text-3xl font-semibold text-center lg:hidden text-skin-inverted dark:text-white">
              acadmi
            </h1>

            <div className="flex items-center mt-6 ">
              <button
                type="button"
                onClick={googleAuthHandler}
                className="flex items-center justify-center w-full px-6 py-2 text-sm font-medium transition-colors duration-200 transform bg-gray-100 border rounded-md hover:bg-gray-200 focus:outline-none"
              >
                <StaticImage
                  src="../images/google.png"
                  className="w-4 h-4"
                  alt="google logo"
                  placeholder={"tracedSVG"}
                />

                <span className="mx-2">Sign in with Google</span>
              </button>
            </div>
            <div className="flex items-center justify-center m-2 text-gray-400">
              or
            </div>

            <form className="mt-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="username"
                  className="block text-sm text-gray-800 dark:text-gray-200"
                >
                  Email
                </label>
                <input
                  type="text"
                  ref={emailRef}
                  required
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                />
              </div>

              <div className="mt-4">
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm text-gray-800 dark:text-gray-200"
                  >
                    Password
                  </label>
                </div>

                <input
                  type="password"
                  ref={passwordRef}
                  required
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                />
              </div>
              {error && <p className="mt-5 text-red-400">{error}</p>}
              <div className="mt-6">
                <button
                  disabled={loading}
                  className="w-full py-5 btn disabled:opacity-70"
                >
                  <div className="flex justify-center text-center">
                    <CubeTransparentIcon
                      className={`${
                        loading ? "animate-spin w-5 h-5 mr-2" : "hidden"
                      }`}
                    />{" "}
                    Login
                  </div>
                </button>
              </div>
            </form>

            <div className="flex flex-col items-center justify-between hidden mt-4">
              <div className="mb-8">
                <a
                  href="#"
                  className="text-xs text-gray-600 dark:text-gray-400 hover:underline"
                >
                  Forget Password?
                </a>
              </div>
            </div>

            <p className="mt-8 font-light text-center text-gray-400 ">
              <Link
                to={`/register`}
                href="#"
                className="font-medium text-gray-800 dark:text-gray-200 hover:underline"
              >
                Create your account
              </Link>
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default Login

import React from "react"
import { navigate } from "gatsby"
import { useAuth } from "../components/context/auth"
import Loader from "../components/utils/Loader"
import Login from "./login"

const Index = () => {
  const { currentUser } = useAuth()

  if (currentUser) {
    navigate("/app/home", { replace: true })
  }

  return (
    <>
      {currentUser && (
        <div className="flex h-screen items-center justify-center w-full">
          <Loader />
        </div>
      )}
      {!currentUser && <Login />}
    </>
  )
}

export default Index
